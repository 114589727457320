import React from 'react';

import CardContainer from '@rotaryintl/harris-card/dist/card-container/card-container.component';
import IconFont from '@rotaryintl/harris-icon-font';
import { Column, ColumnLayout } from '@rotaryintl/myrotary-column-layout';

import { useTranslation } from '@external/react-i18next';

import { Representatives } from '@typings/operations';

const CDSFinancialCard: React.FC<Representatives> = ({
  representativeType,
  representativeInfo: representativesInfo,
}) => {
  const { t } = useTranslation();
  return (
    <section className="mt-8 cds-card-section">
      <CardContainer className="rwc-card grid grid-col-1 cds-card-container">
        <>
          <section className="cds-card-heading">
            <h4 className="cds-title-color cds-card-heading-size cds-card-heading-decor">
              {representativeType === 'CDS'
                ? t(
                    'cds-financial.cds-headline',
                    'Member and Participant Experience'
                  )
                : t('cds-financial.finance-headline', 'Finance')}
            </h4>
            <p className="cds-title-color text-wrap normal-case cds-card-description-size cds-card-description-decor">
              {representativeType === 'CDS' ? (
                <>
                  {t(
                    'cds-financial.cds-description',
                    '<p>For club operations, regional insights, new club formation, diversity, conflict resolution, and harassment prevention, contact your <strong>Club and District Support</strong> team. For strategies, resources, data, and guidance on how to attract, engage, and retain members, along with assistance with innovative club models and our membership leads management system, connect with <strong>Membership Development.</strong> If no regional membership officer is listed, email <a href="mailto:membershipdevelopment@rotary.org">membershipdevelopment@rotary.org</a>.</p>'
                  )}
                </>
              ) : (
                <>
                  {t(
                    'cds-financial.finance-description',
                    `<p>Contact your District's Financial Representative to discuss invoice details. To update your club membership or officers, please contact <a href="mailto:Data@Rotary.org">Data@Rotary.org</a>.</p>`
                  )}
                </>
              )}
            </p>
          </section>
          <h5 className="mb-1 cds-title-color normal-case cds-card-position-size cds-card-position-decor">
            {representativesInfo?.[0]?.department}
          </h5>
          <ColumnLayout className="m-0 p-0 cds-column-layout">
            {representativesInfo?.map(representativeInfo => (
              <Column span="6" key={representativeInfo?.id}>
                <div>
                  <div className="cds-title-color normal-case cds-card-contact-size cds-card-contact-decor mb-4">
                    {`${representativeInfo?.name}, ${representativeInfo?.title}`}
                  </div>
                  {representativeInfo?.phone && (
                    <div className="rwc-contact-card__phone-container cds-card-position-size flex mb-1">
                      <div className="rwc-contact-card__icon rwc-icon mr-1">
                        <IconFont type="fas-phone" aria-hidden="true" />
                      </div>
                      <div className="cds-title-color cds-card-phone-size cds-card-phone-decor">
                        {representativeInfo?.phone}
                      </div>
                    </div>
                  )}
                  {representativeInfo?.fax && (
                    <div className="rwc-contact-card__email-container cds-card-position-size flex mb-1">
                      <div className="rwc-contact-card__icon rwc-icon mr-1">
                        <IconFont type="fas-fax" aria-hidden="true" />
                      </div>
                      <div className="cds-title-color cds-card-phone-size cds-card-phone-decor">
                        {representativeInfo?.fax}
                      </div>
                    </div>
                  )}
                  {representativeInfo?.email && (
                    <div className="rwc-contact-card__email-container cds-card-position-size flex mb-1">
                      <div className="rwc-contact-card__icon rwc-icon mr-1">
                        <IconFont type="fas-envelope" aria-hidden="true" />
                      </div>
                      <a
                        href={`mailto:${representativeInfo?.email}`}
                        className="cds-card-email-decor cds-email-color"
                      >
                        {representativeInfo?.email}
                      </a>
                    </div>
                  )}
                </div>
              </Column>
            ))}
          </ColumnLayout>
        </>
      </CardContainer>
    </section>
  );
};
export default CDSFinancialCard;
